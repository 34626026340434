export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [];

export const dictionary = {
		"/": [10],
		"/administracion": [11,[2]],
		"/administracion/categoria": [12,[2]],
		"/administracion/marca": [13,[2]],
		"/administracion/material_empaque": [15,[2]],
		"/administracion/material": [14,[2]],
		"/administracion/planta": [16,[2]],
		"/administracion/segmento": [17,[2]],
		"/administracion/semielaborado": [18,[2]],
		"/administracion/sustancia": [19,[2]],
		"/configuracion": [20,[3]],
		"/configuracion/ficha_tecnica": [21,[3]],
		"/configuracion/formulacion": [22,[3]],
		"/configuracion/laboratorio": [23,[3]],
		"/configuracion/laboratorio/servicio/[id]": [24],
		"/configuracion/material": [25,[3]],
		"/configuracion/producto": [26,[3]],
		"/configuracion/segmento": [27,[3]],
		"/empaque": [28],
		"/empaque/[id]": [29,[4]],
		"/empaque/[id]/salidas": [30,[4]],
		"/formulacion": [31],
		"/formulacion/semielaborado/[id]": [35],
		"/formulacion/[mode]/[id]": [32,[5]],
		"/formulacion/[mode]/[id]/pesos": [33,[5]],
		"/formulacion/[mode]/[id]/productos": [34,[5]],
		"/informe": [36,[6]],
		"/informe/gestion": [37,[6]],
		"/informe/negocio": [38,[6]],
		"/informe/reporte": [39,[6]],
		"/laboratorio": [40,[7]],
		"/laboratorio/[id]": [41,[7]],
		"/login": [42],
		"/metadata": [43,[8]],
		"/metadata/[id]": [44,[8]],
		"/paquete": [45],
		"/paquete/[mode]/[id]": [46,[9]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';